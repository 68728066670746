import ReactOnRails from 'react-on-rails';

import AddConfirmToCalendar from '../bundles/AddConfirmToCalendar/components/AddConfirmToCalendar';
import MapWithMarker from '../bundles/MapWithMarker/components/MapWithMarker';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AddConfirmToCalendar,
  MapWithMarker
});
